import React, { useState, useEffect, Component } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Chart } from 'chart.js'
import teamCover from '../images/teamBanner.jpg';
import gStore from '../images/googleStore.png';
import aStore from '../images/appleStore.png';
import e2 from '../images/e2.jpg';
import e1 from '../images/e1.jpg';
import linkedIn from '../images/linkedin.png';
import waves from '../images/waves.png';
import Navigation from './nav';


import{InMemoryCache,
     ApolloClient,
     gql
     } from '@apollo/client'
     
     const client = new ApolloClient({
       cache:  new InMemoryCache(),
       uri: "https://lam-cms.ford.com.ng/graphql",
       fetchOptions: {
         mode: 'no-cors',
       }
     });
const Team = () => {
     window.scrollTo(0, 0)

     const [teamBoardContent, setteamBoardContent] = useState([]);
     const [teamBanner, setteamBanner] = useState("");
     const [teamBannerText, setteamBannerText] = useState("");
     const [teamProfessionalContent, setteamProfessionalContent] = useState([]);


     useEffect( () => {
    
          client
        .query({
          query: gql`
          query{
               pages {
                    edges {
                      node {
                        people {
                          bannerImageTeam{
                            mediaItemUrl
                          }
                          bannerTextTeam
                          fieldGroupName
                        boardLam {
                          bio
                          fieldGroupName
                          firstName
                          jobrole
                          lastName
                          linkedin
                          headshot{
                            mediaItemUrl
                          }
                        }
                          lamProfessionals {
                            bio
                            fieldGroupName
                            firstname
                            jobRole
                            lastname
                            linkedinProfile
                            headshot{
                              mediaItemUrl
                            }
                          }
                        }
                      }
                    }
                  }
        }
          `,
        })
        .then(async result => { 
          let professionalsArray = []
      
              console.log(result.data.pages.edges)
              
               setteamBanner(result.data.pages.edges[7].node.people.bannerImageTeam.mediaItemUrl)
               setteamBannerText(result.data.pages.edges[7].node.people.bannerTextTeam)
               setteamBoardContent(result.data.pages.edges[7].node.people.boardLam)
               setteamProfessionalContent(result.data.pages.edges[7].node.people.lamProfessionals)

               
        })
      
         
         }, [])



         let professionalDetails
         let boardDetails

         if(teamProfessionalContent.length){ 
          console.log(teamProfessionalContent)
            professionalDetails = teamProfessionalContent.map((professionalDetails, i) => 
         
            <div className="col-10 col-md-3  teamPicHeight standard-padding-rl-tb-min teamBoxMargin">
            <div className="containerBox">
             <img src={professionalDetails.headshot.mediaItemUrl} width="100%"/>
            </div>
   
            <div className="text-box">
            <h4 className="headerFont orangeText"> {professionalDetails.firstname} <span className="bodyFont teamFirstNameDesign orangeText"> {professionalDetails.lastname}</span></h4>
            <hr className='barStyle mOff' />
            <p className="teamFirstNameDesign orangeText"> {professionalDetails.jobRole}</p>
            <div className="row"> 
               
                  <div className="col-5">
                   <Link className="orangeText bodyFont teamHover" to={professionalDetails.firstname}> View Profile </Link>
                  </div>
             </div>
             
             </div>
             </div>
           
           
                 
              )
           
             
           }



           if(teamBoardContent.length){ 
               console.log(teamBoardContent)
                 boardDetails = teamBoardContent.map((boardDetails, i) => 
              
                 <div className="col-10 col-md-3  teamPicHeight standard-padding-rl-tb-min teamBoxMargin">
                 <div className="containerBox">
                  <img src={boardDetails.headshot.mediaItemUrl} width="100%"/>
                 </div>
        
                 <div className="text-box">
                 <h4 className="headerFont orangeText"> {boardDetails.firstName} <span className="bodyFont teamFirstNameDesign orangeText"> {boardDetails.lastName}</span></h4>
                 <hr className='barStyle mOff' />
                 <p className="orangeText teamFirstNameDesign"> {boardDetails.jobRole}</p>
                 <div className="row"> 
                
                       <div className="col-5">
                        <Link className="orangeText bodyFont teamHover" to={boardDetails.firstName}> View Profile </Link>
                       </div>
                  </div>
                  
                  </div>
                  </div>
                
                
                      
                   )
                
                  
                }



    return(

<>
<Navigation currentVertical="" />  
<div className="standard-banner-padding row fullWidth mOff" style={{backgroundImage:`url(${teamBanner})`, backgroundSize:"cover", backgroundPosition:"center"}}>
    <h1 className="headerFont heavyFont orangeText top-text-clearance-2"> 
      {teamBannerText}
    </h1>
    <h6 className='bodyFont whiteText'> Effective yeild of <b>14.78%</b> as at 13 July 2023</h6>
</div>

<div className="row fullWidth mOff">
    <div className='col-12 standard-padding-tb'> 
        <h4 className="headerFont text-center"> 
           People of LAM
        </h4>
        <hr className='barStyleGP mx-auto' />

  
    <div className="mOff">
    
 
<div className="col-12 col-md-5 mx-auto">
    <ul className="nav nav-tabs borderBottomOff" id="myTab" role="tablist">
      <li className="nav-item halfWidth divider" role="presentation">
       <button className="nav-link active fullWidth borderControl midSizeText blackText headerFont" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Board</button>
     </li>
     <li className="nav-item halfWidth dividerB" role="presentation">
        <button className="nav-link fullWidth borderControl midSizeText blackText headerFont" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Professionals</button>
     </li>
 
    </ul>
</div>

<div className="tab-content" id="myTabContent">
  <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
   
 
    <div className="row standard-padding-rl-tb fullWidth mOff" id="" >

     {boardDetails}
  
    </div>
    




    </div>
  <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
  <div className="row standard-padding-rl-tb mOff">
  {professionalDetails}
  

          </div>

 

</div>
<div className="teamPicHeight teamClearance" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}> 
             
             </div>
     </div>
   
    </div>

 
    </div>

</div>

<Outlet />
</>
    )


}

export default Team;