import React, { useState, useEffect, Component } from 'react';
import careerImage from '../images/teamBanner.jpg';
import {Link} from 'react-router-dom';
import { useForm } from "react-hook-form";
import gStore from '../images/googleStore.png';
import aStore from '../images/appleStore.png';
import waves from '../images/waves.png';
import table from '../images/table.jpg';
import recruit from '../images/recruit.jpg';
import badge from '../images/badge.png';
import submit from '../images/submit.jpg';
import map from '../images/lam-map.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCaretRight, faSearch, faPhone, faHouse, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import Navigation from './nav';


const Contact = () => {

    let { register, handleSubmit, watch, formState: { errors } } = useForm()


    let onSubmit = async function (data){
  
      
  
      
        let submitFinanceSchemeRequest = await fetch('https://charisbrain1.herokuapp.com/charis/fordwebsiteservices/mail/registration',{ 
            method: "POST",
            body:JSON.stringify(data),
            headers:{
              'Content-Type':'application/JSON',
              'Accept': 'application/JSON'
            },
            mode:'cors' 
          })
        
          .then(response => response.json())
          .then(response => { 
              alert(response)
      
          })
         
    
    
    };




    async function handleVariantChange(event) {
        let selectedVariant =   $('.variantType').val()
    
        if(selectedVariant === 'others'){
            $('.variantAlt').fadeIn('slow') 
         }else{
             $('.variantAlt').fadeOut('fast')  
         }
    
    
      
      }

    return(

<>
<Navigation currentVertical="" />  

<div className="standard-banner-padding row fullWidth mOff" style={{backgroundImage:`url(${careerImage})`, backgroundSize:"cover", backgroundPosition:"center"}}>
    <h1 className="headerFont heavyFont orangeText top-text-clearance-2"> 
    Let's have a chat!
    </h1>
    <h6 className='bodyFont whiteText'> Find details of our contact channels below </h6>
</div>

<div className="row fullWidth mOff" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}>
    <div className='col-12 standard-padding-tb marginGlass2 top-text-clearance-2 '> 
        <h4 className="headerFont text-center"> 
           Get in touch with us
        </h4>
        <hr className='barStyleGP mx-auto' />
        </div>

    <div className="row fullWidth mOff standard-padding-bottom">
  
      
    <div className='col-12 orangeShadow mx-auto'>
        <div className="row standard-padding-rl-tb" id="" > 
        
           <div className="col-12 col-md-8 standard-padding-rl-tb-process text-center mobTrans" id="hiringProcess">
           <form className='row mx-auto testDriveForm' onSubmit={handleSubmit(onSubmit)} >

<label className="bodyFont">
  Fullname
</label>
<input className='form-control  form-control-2' type="text" placeholder='Firstname' {...register("firstname", {required:true})}/>

<label className="bodyFont">
  Email
</label>
<input className='form-control form-control-2' type="email" placeholder='Email'{...register("email", {required:true})} />

<label className="bodyFont">
  What would you like to speak to us about?
</label>
<input className='form-control form-control-2' type="textarea" placeholder='Your message' {...register("message", {required:true})}/>
 


<div className='col-12 col-md-12'>
<button  className="btn btn-primary leadwayOrange btnDesign headerFont marginGlass">Submit</button>

</div>
</form>
           </div>
           <div className="col-12 col-md-4 whiteText blackBg standard-padding-rl-tb" id="">
            
            <h5 className='headerFont standard-padding-tb'> CONTACT DETAILS </h5>

            <div className="col-12"> 
           <h6 className='headerFont standard-padding-tb'> <FontAwesomeIcon className='orangeText' icon={faPhone} /> +234 703 705 2220 </h6> 
            </div>
            <div className="col-12"> 
           <h6 className='headerFont standard-padding-tb'> <FontAwesomeIcon className='orangeText' icon={faEnvelope} /> info@leadway.com </h6> 
            </div>
            <div className="col-12"> 
           <h6 className='headerFont standard-padding-tb'> <FontAwesomeIcon className='orangeText' icon={faHouse} /> Leadway House, Alfred Rewane Road, Ikoyi Lagos </h6> 
            </div>
           </div>
           </div>
        </div>

        <div className="col-12 col-md-7 mx-auto standard-padding-tb"> 
         <img src={map} className="img-fluid" width="100%" />
        </div>

    </div>




</div>
     

</>

    )
}

export default Contact