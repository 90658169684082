import React, { useState, useEffect, Component } from 'react';
import {Link} from 'react-router-dom';
import { useForm } from "react-hook-form";
import gStore from '../images/googleStore.png';
import aStore from '../images/appleStore.png';
import waves from '../images/waves.png';
import table from '../images/table.jpg';
import recruit from '../images/recruit.jpg';
import badge from '../images/badge.png';
import submit from '../images/submit.jpg';
import $ from 'jquery';
import Navigation from './nav';

import{InMemoryCache,
  ApolloClient,
  gql
  } from '@apollo/client'
  
  const client = new ApolloClient({
    cache:  new InMemoryCache(),
    uri: "https://lam-cms.ford.com.ng/graphql",
    fetchOptions: {
      mode: 'no-cors',
    }
  });

const Career = () => {

  window.scrollTo(0, 0)

  const [careerText , setCareerText] = useState("");
  const [careerImage , setCareerImage] = useState("");
  const [cvImage , setCvImage] = useState("");
  const [openings, setOpenings] = useState([]);
  const [learnAboutBg, setLearnAboutBg] = useState("");
  

    let { register, handleSubmit, watch, formState: { errors } } = useForm()

    let onSubmit = async function (data){

        let submitFinanceSchemeRequest = await fetch('https://lamwebservices-bc4dfe5fe774.herokuapp.com/lamwebservices/v1/mailer-demon/helpdesk/careers',{ 
            method: "POST",
            body:JSON.stringify(data),
            headers:{
              'Content-Type':'application/JSON',
              'Accept': 'application/JSON'
            },
            mode:'cors' 
          })      
          .then(response => response.json())
          .then(response => { 
              alert(response)
      
          })
    };



    useEffect( () => {
    
      client
    .query({
      query: gql`
      query{
        pages {
          edges {
            node {
              careers {
                careerImage {
                  mediaItemUrl
                }
                careerText
                cvImage {
                  mediaItemUrl
                }
                learnAboutBg {
                  mediaItemUrl
                }
                openings {
                  roleDescription
                  roles
                }
              }
            }
          }
        }
    }
      `,
    })
    .then(async result => { 
     
  
          console.log(result.data.pages.edges)
          setCareerText(result.data.pages.edges[0].node.careers.careerText)
          setCareerImage(result.data.pages.edges[0].node.careers.careerImage.mediaItemUrl)
          setCvImage(result.data.pages.edges[0].node.careers.cvImage.mediaItemUrl)
          setLearnAboutBg(result.data.pages.edges[0].node.careers.learnAboutBg.mediaItemUrl)
          setOpenings(result.data.pages.edges[0].node.careers.openings)
       
    })
  
     
     }, [])


     let jobListingDetail

     if(openings.length){ 
      
         jobListingDetail = openings.map((jobListingDetail, i) => 
  
         <div className="card btnDesignAcc">
         <div className="card-header text-center" id="headingOne">
         <a class="btn orangeText noDecoration headerFont" data-bs-toggle="collapse" href={`#collapseOne${i}`} role="button" aria-expanded="false" aria-controls="collapseExample">
         {jobListingDetail.roles}
       </a>
     
         </div>
     
         <div id={`collapseOne${i}`} className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
           <div className="card-body">
           {jobListingDetail.roleDescription}</div>

           <div className='col-12 text-center standard-padding-tb'> 
           <button className="btn btn-primary btnDesign headerFont" id={jobListingDetail.roles}> Apply now </button>
           </div>
         </div>


       </div>
       
       
          )
       
         
       }


    return(

<>

<Navigation currentVertical="" />  

<div className="standard-banner-padding row fullWidth mOff" style={{backgroundImage:`url(${careerImage})`, backgroundSize:"cover", backgroundPosition:"center"}}>
    <h1 className="headerFont heavyFont orangeText top-text-clearance-2"> 
    {careerText}
    </h1>
    <h6 className='bodyFont whiteText'> Apply for today or the future</h6>
</div>
<div className="row fullWidth mOff" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}>
    <div className='col-12 standard-padding-tb'> 
        <h4 className="headerFont text-center"> 
           Careers
        </h4>
        <hr className='barStyleGP mx-auto' />
        </div>

        <div className="col-10 orangeShadow mx-auto" id="about"> 
    <div className="text-left row standard-padding-rl-tb">
    <div className="col-12 col-md-6 standard-padding-rl-tb"> 
        <h3 className="orangeText headerFont"> 
        "Find your seat at the table"
         </h3>
         <hr className='barStyleGP' />

         <p className="bodyFont">
         We want you here. At Leadway Asset Managment, we are intentional about excellence, value and inclusivity because we recognize the power of any organisation is its people.We love the 
         work we do in opening portals of wealth for people and organisations. We know you will love it too.
             </p>

             <a href="#" className="btn btn-primary btnDesign headerFont">Submit your CV</a>

     </div>
     <div className="col-12 col-md-6 standard-padding-rl-tb" > 
     <div id="accordion">
      {jobListingDetail}
  </div>

     </div>
    </div>

   
  
     
    </div>


    <div className="row fullWidth mOff standard-padding-tb">
        <div className="col-12 col-md-7 blackBg"> 
        <div className='col-12 col-md-12'>
        <div className="row" > 
        
           <div className="col-12 col-md-12 standard-padding-rl-tb-process" id="hiringProcess">
                <h4 className="headerFont">
                    Learn about our hiring process
                     </h4>
                     <hr className='barStyleGPLong' />

                     <p className='bodyFont'>
                       We are excited to get to know you and help you get to know us 
                       through our interview process
                     </p>

                     <a href="#" className="btn btn-primary btnDesign headerFont"> Contact us </a>

           </div>
           </div>
        </div>

        </div>
        <div className="col-12 col-md-5 pOff"> 
         <img src={learnAboutBg} className="img-fluid" width="100%" />
        </div>


    </div>

    <div className="">
        
    <div className="col-12 col-md-10 mx-auto orangeShadow pOff"> 
     
     <div className="card col-12">
  <img className="card-img-top" id="process" src={cvImage} alt="Card image cap"/>
  <div className="card-body text-center">
  <h3 className="orangeText headerFont text-center"> 
           CV Submissions 
         </h3>
         <hr className='barStyleGP mx-auto' />
<form className='row mx-auto fullWidth' onSubmit={handleSubmit(onSubmit)} >
  <div className="col-12 col-md-6">
  <label className="bodyFont">
  Firstname
</label>
<input className='form-control  form-control-2' type="text" placeholder='Firstname' {...register("firstname", {required:true})}/>

  </div>

  <div className="col-12 col-md-6">
  <label className="bodyFont">
  Lastname
</label>
<input className='form-control form-control-2' type="text" placeholder='Lastname' {...register("lastname", {required:true})}/>
  </div>

  <div className="col-12 col-md-6">
  <label className="bodyFont">
  Email
</label>
<input className='form-control form-control-2' type="email" placeholder='Email'{...register("email", {required:true})} />

  </div>


<div className="col-12 col-md-6">
<label className="bodyFont">
  Linkedin Profile
</label>
<input className='form-control form-control-2' type="text" placeholder='Linkedin Url' {...register("linkedin", {required:true})}/>
</div>

<div className="col-12 col-md-6">
<label className="bodyFont">
  Preferred Role
</label>
<select className='form-control form-control-2' {...register("role", {required:true})}>
      <option value="none">Investment Trader</option>
      <option value="Immediately">Head of Investments</option>
      <option value="4 weeks">Client Service Specialist </option>
      <option value="12 weeks"> Institutional sales associate </option>
      <option value="12 weeks"> Others </option>

</select>


</div>


<div className="col-12 col-md-6">
<label className="bodyFont">
  Upload CV
</label>
<input className="form-control form-control-2" type="text" placeholder='Upload CV' {...register("cv", {required:true})} />
</div>
<div className='col-12 col-md-6'>
<button  className="btn btn-primary btnDesign">Submit CV</button>

</div>
</form>
     </div>
</div>
     </div>
        </div> 



</div>
     

</>

    )
}

export default Career