import React, { useState, useEffect, Component } from 'react';
import { Chart } from 'chart.js'
import Card from 'react-bootstrap/Card'
import {Link} from 'react-router-dom';
import aboutCover from '../images/calendar.jpg';
import gStore from '../images/googleStore.png';
import aStore from '../images/appleStore.png';
import why from '../images/why.jpg';
import process from '../images/process.png';
import table from '../images/table.jpg';
import e1 from '../images/e1.jpg';
import e2 from '../images/e2.jpg';
import waves from '../images/waves.png';
import Navigation from './nav';



import{InMemoryCache,
  ApolloClient,
  gql
  } from '@apollo/client'
  
  const client = new ApolloClient({
    cache:  new InMemoryCache(),
    uri: "https://lam-cms.ford.com.ng/graphql",
    fetchOptions: {
      mode: 'no-cors',
    }
  });

const About = () => {



     
  const [aboutLandingImage , setAboutLandingImage] = useState("");
  const [landingCopy , setLandingCopy] = useState(""); 
  const [aboutLandingText , setAboutLandingText] = useState("");
  const [whyUsImage , setWhyUsImage] = useState("");
  const [whyUsDescription , setWhyUsDescription] = useState("");
  const [coreValuesPicture, setCoreValuesPicture] = useState("");
  const [coreValues, setCoreValues] = useState([])
  const [ourProcess, setOurProcess] = useState("");
  const [ourProcessImage, setOurProcessImage] = useState("");
  const [findYourSeat, setFindYourSeat] = useState("");
  const [findYourSeatImage, setFindYourSeatImage] = useState("");
  const [citizenship, setCitizenship] = useState("");
  const [person1Image, setPerson1Image] = useState("");
  const [person2Image, setPerson2Image] = useState("");
  const [person3Image, setPerson3Image] = useState("");
  
 


  useEffect( () => {
    

    window.scrollTo(0, 0)
     
    client
  .query({
    query: gql`
    query{
      pages {
        edges {
          node {
            aboutus {
              aboutLandingCopy
              aboutLandingText
              citizenship
              fieldGroupName
              findYourSeat
              ourProcess
              whyUsDescription
              aboutLandingImage {
                mediaItemUrl
              }
              coreValues {
                id
                mediaItemUrl
              }
              person1 {
                mediaItemUrl
              }
              person2 {
                mediaItemUrl
              }
              person3 {
                mediaItemUrl
              }
              whyUsImage {
                mediaItemUrl
              }
              coreValuesChain {
                alphabet{
                  mediaItemUrl
                }
                alphabetDescription
                alphabetMeaning
              }
              ourProcessImage{ 
                mediaItemUrl
              }
              findYourSeatImage {
                mediaItemUrl
              }
            }
          }
        }
      }
  }
    `,
  })
  .then(async result => { 

        console.log(result.data.pages.edges)
        setAboutLandingImage(result.data.pages.edges[8].node.aboutus.aboutLandingImage.mediaItemUrl)
        setAboutLandingText(result.data.pages.edges[8].node.aboutus.aboutLandingText)
        setLandingCopy(result.data.pages.edges[8].node.aboutus.aboutLandingCopy)
        setCoreValuesPicture(result.data.pages.edges[8].node.aboutus.coreValues.mediaItemUrl)
        setOurProcess(result.data.pages.edges[8].node.aboutus.ourProcess)
        setWhyUsImage(result.data.pages.edges[8].node.aboutus.whyUsImage.mediaItemUrl)
        setWhyUsDescription(result.data.pages.edges[8].node.aboutus.whyUsDescription)
        setOurProcessImage(result.data.pages.edges[8].node.aboutus.ourProcessImage.mediaItemUrl)
        setFindYourSeat(result.data.pages.edges[8].node.aboutus.findYourSeat)
        setFindYourSeatImage(result.data.pages.edges[8].node.aboutus.findYourSeatImage.mediaItemUrl)
        setPerson1Image(result.data.pages.edges[8].node.aboutus.person1.mediaItemUrl)
        setPerson2Image(result.data.pages.edges[8].node.aboutus.person2.mediaItemUrl)
        setPerson3Image(result.data.pages.edges[8].node.aboutus.person3.mediaItemUrl)
        setCitizenship(result.data.pages.edges[8].node.aboutus.citizenship)
       await setCoreValues(result.data.pages.edges[8].node.aboutus.coreValuesChain)

        console.log(result.data.pages.edges[8].node.aboutus.coreValues)



  })




   
   }, [])


   //extract featured image

 let coreValuesListingDetails ;

 if(coreValues.length){ 

  coreValuesListingDetails= coreValues.map((coreValuesListingDetails, i) => 

  <div className="col-12 col-md-4 standard-padding-tb-2">
  <Card className="text-center borderControl pOff mBMobile values whiteBackground" style={{ width: '100%' }}>
  
  <Card.Body>
    
    <Card.Text className="whiteBg mOff values">
      <div className="col-12 standard-padding-tb-3">
      <img className="monsterText orangeText" src={coreValuesListingDetails.alphabet.mediaItemUrl} width="30%" />
      </div>
      <div className="col-12">
      <h5 className="orangeText standard-padding-tb mOff"> {coreValuesListingDetails.alphabetMeaning} </h5>
      </div>
    
    
    </Card.Text>

    <Card.Title className="text-center  vertical-text-positon">
        <h6 className="standard-padding-tb orangeText bodyFont"> {coreValuesListingDetails.alphabetDescription} </h6> 
    </Card.Title>
   
  </Card.Body>
</Card>
  </div>


      
   )

  
}


    return(

<>
<Navigation currentVertical="" />  
<div className="standard-banner-padding row fullWidth mOff" style={{backgroundImage:`url(${aboutLandingImage})`, backgroundSize:"cover", backgroundPosition:"center"}}>
    <h1 className="headerFont heavyFont orangeText top-text-clearance-2 col-12 col-md-9"> 
{landingCopy}
    </h1>
    <h6 className='bodyFont whiteText'> Effective yeild of <b>14.78%</b> as at 13 July 2023</h6>
</div>

<div className="row fullWidth mOff">
    <div className='col-12 standard-padding-tb'> 
        <h4 className="headerFont text-center"> 
           Our Firm
        </h4>
        <hr className='barStyleGP mx-auto' />

    <div className="col-10 orangeShadow mx-auto" id="about"> 
    <div className="text-left row standard-padding-rl-tb">
    <div className="col-12 col-md-6"> 
        <h3 className="orangeText headerFont"> 
        Who We Are
         </h3>
         <hr className='barStyleGP' />

         <p className="bodyFont">
        {aboutLandingText}  </p>

            <a href="/" className='bodyFont btn btn-primary btnDesign'> 
             Our Investment Services
            </a>
     </div>
     <div className="col-12 col-md-6" > 
     
     </div>
    </div>

   
  
     
    </div>

    <div className="row standard-padding-rl-tb fullWidth mOff" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}>
        <div className="text-center  col-12">
        <h3 className="orangeText headerFont"> 
           Why Us
         </h3>
         <hr className='barStyleGP mx-auto' />
        </div>

        <div className="text-center col-12 col-md-6" > 
       <img src={whyUsImage} className="img-fluid" width="70%" />
     </div>
    <div className="col-12 col-md-5"> 
       
        

         <p className="bodyFont">
      {whyUsDescription}   </p>

     </div>
   
    </div>


    <div className="row standard-padding-rl-tb fullWidth mOff" id="flex1">
 
     <div className="col-12 col-md-10 mx-auto orangeShadow pOff" id="values"> 
     
     <div className="card col-12">
  <img className="card-img-top" id="process" src={ourProcessImage} alt="Card image cap"/>
  <div className="card-body text-center">
  <h3 className="orangeText headerFont text-center"> 
           Our Process
         </h3>
         <hr className='barStyleGP mx-auto' />
    <p className="card-text"> 
         {ourProcess}
      </p>
    <Link href="/contact" className="btn btn btn-primary btnDesign headerFont">Contact us</Link>
  </div>
</div>
     </div>
   
    </div>

    <div id="citizen" className="row standard-padding-rl-tb-citizen fullWidth mOff" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}>
        <div className="text-center  col-12">
        <h3 className="orangeText headerFont"> 
          Citizenship
         </h3>
         <hr className='barStyleGP mx-auto' />
        </div>
    <div className="col-12 col-md-9 mx-auto"> 
       
         <p className="bodyFont">
        {citizenship}  </p>
     </div>
   
    </div>

    <div className="row standard-padding-tb fullWidth mOff" id="managerStart">

    <div className="row standard-padding-rl-tb fullWidth mOff" id="flex1">
 
 <div className="col-12 col-md-10 mx-auto orangeShadow pOff" id="values"> 
 
 <div className="card col-12">
<img className="card-img-top" id="process" src={findYourSeatImage} alt="Card image cap"/>
<div className="card-body text-center">
<h3 className="orangeText headerFont text-center"> 
       Our Process
     </h3>
     <hr className='barStyleGP mx-auto' />
<p className="card-text"> 
     {findYourSeat}
  </p>
<Link href="/contact" className="btn btn btn-primary btnDesign headerFont">Contact us</Link>
</div>
</div>
 </div>

</div>

        <div className="text-left  col-12 col-md-6 blackBg standard-padding-rl-tb" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}>
        <h3 className="orangeText headerFont"> 
           Meet our managers
         </h3>
         <hr className='barStyleGP' />
         <p className="bodyFont whiteText">
         Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
         </p>

         <Link to="/team" className='bodyFont btn btn-primary btnDesign'> 
             Find Out More
         </Link>


        </div>
    <div className="col-12 col-md-2" style={{backgroundImage:`url(${person1Image})`, backgroundSize:"cover", backgroundPosition:"center"}}> 
      
        
     </div>
     <div className="col-12 col-md-2" style={{backgroundImage:`url(${person2Image})`, backgroundSize:"cover", backgroundPosition:"center"}}> 
       
        
       </div>
       <div className="col-12 col-md-2" style={{backgroundImage:`url(${person3Image})`, backgroundSize:"cover", backgroundPosition:"center"}}> 
       
        
       </div>


   
    </div>

    <div className="text-center  col-12">
        <h3 className="orangeText headerFont"> 
           Our Core Values
         </h3>
         <hr className='barStyleGP mx-auto' />
        </div>
    <div className="col-12 col-md-9 mx-auto"> 
       
         <p className="bodyFont">
         These are the values that impact our services to our customers and serve as a foundation on which the business is constructed.  </p>
     </div>

   
     <div className="row fullWidth">
  {coreValuesListingDetails}

     </div>

    </div>


</div>
</>
    )
}
export default About;